

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby";
import ResAppBar from '../components/res-app-bar';
import Footer from '../components/footer';
import Helmet from 'react-helmet';
import '../../global.css'

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          env
        }
      }
    }
  `)
  let { site: {siteMetadata: {env}} } = data;
  return (
      <div>
        <Helmet>
          {env === 'staging' && <meta name="robots" content="noindex" />}
        </Helmet>
        <ResAppBar pathname={location.pathname} />
        <main>{children}</main>
        <Footer />
      </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
