import React from 'react';
import {
  AppBar, Toolbar, Typography,
  Grid, SwipeableDrawer, Container,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import BlixIcon from '../images/logo-blix.svg';
import {Link} from 'gatsby'

class ResAppBar extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      drawerActivate:true, 
      drawer:false, 
      right: false,
    };
    this.createDrawer = this.createDrawer.bind(this);
    this.destroyDrawer = this.destroyDrawer.bind(this);    
  }

  //Small Screens
  createDrawer(){
    return (
      <div >
       
        <AppBar className="app-bar-wrapper-on-small-screen">
            <Container maxWidth="lg" className="container">
                <Toolbar>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                      <Typography><Link to="/" className="blix-icon"><BlixIcon /></Link></Typography>
                      <MenuIcon className="menu-icon" onClick={()=>{this.setState({drawer:true})}} />
                      
                    </Grid>
                </Toolbar>
            </Container>
        </AppBar>

        <SwipeableDrawer anchor="right" open={this.state.drawer} onClose={()=>{this.setState({drawer:false})}} onOpen={()=>{this.setState({drawer:true})}}>
           <div  tabIndex={0}
           style={{outline: 'none'}}
             role="button"
             onClick={()=>{this.setState({drawer:false})}}
             onKeyDown={()=>{this.setState({drawer:false})}}>
            <div className="mobile_nav_bar" style={{paddingTop: '20px'}}>
              <div  style={{width: '200px', color: '#000', fontWeight: 'bold', padding: '0'}}>
                <Link activeClassName="active" to="/" ><div style={{ color: '#777777', padding: '15px'}}>Home</div></Link> 
                <Link activeClassName="active" to="/features" ><div style={{ color: '#777777', padding: '15px'}}>Features</div></Link>
                <Link activeClassName="active" to="/pricing" ><div style={{ color: '#777777', padding: '15px'}}>Pricing</div></Link>
                <Link activeClassName="active" to="/news" ><div style={{ color: '#777777', padding: '15px'}}>News</div></Link>
                <a href="https://bluemail.me" style={{textDecoration: 'none'}}><div style={{ padding: '15px', color: '#777777'}}>BlueMail</div></a>
                <Link activeClassName="active" to="/contact-us" ><div style={{ color: '#777777', padding: '15px'}}>Contact Us</div></Link>
                <Link activeClassName="active" to="/download" ><div style={{ color: '#777777', padding: '15px'}}>Download</div></Link>
              </div>
             </div>
         </div>
        </SwipeableDrawer>
      </div>
    );
  }

  //Larger Screens
  destroyDrawer(){
    return (
      <AppBar className="app-bar-wrapper-on-large-screen">
        <Container maxWidth='lg' className="container">
            <Toolbar className="toolbar">
                <Typography className="icon-wrapper"  style={{flexGrow:1, display: 'flex', padding: '10px 10px 10px 20px'}} >
                    <Link to="/" className="blix-hover">
                      <BlixIcon className="blix-icon"/>
                    </Link>
                    <span className="left-toolbar-text" style={{display: 'flex', flexWrap: 'wrap', maxWidth: '150px', height: '40px', borderLeft: '1px solid #959595', paddingLeft: '20px', fontSize: '19px', alignItems: 'center'}}>
                      <span style={{color: '#959595', lineHeight: '0.9'}}>BlueMail</span>
                      <span style={{color: '#959595', lineHeight: '0.9'}}>for Teams</span>
                    </span>
                </Typography>
                <Typography style={{padding: '6px'}}>
                  <Link className={this.props.pathname === '/' ? "active" : ''} to="/" style={{textDecoration: 'none'}}>
                    Home
                  </Link>
                </Typography>
                <Typography style={{padding: '6px'}}><Link className={this.props.pathname === '/features' || this.props.pathname === '/features/' ? "active" : ''} to="/features" style={{textDecoration: 'none'}}>Features</Link></Typography>
                <Typography style={{padding: '6px'}}><Link className={this.props.pathname === '/pricing' || this.props.pathname === '/pricing/' ? "active" : ''} to="/pricing" style={{textDecoration: 'none'}}>Pricing</Link></Typography>
                <Typography style={{padding: '6px'}}><Link className={this.props.pathname === '/news' || this.props.pathname === '/news/' ? "active" : ''} to="/news" style={{textDecoration: 'none'}}>News</Link></Typography>
                <Typography style={{padding: '6px'}}><a href="https://bluemail.me" style={{textDecoration: 'none'}}>BlueMail</a></Typography>
                <Typography style={{padding: '6px', minWidth:'100px'}}><Link className={this.props.pathname === '/contact-us/' || this.props.pathname === '/contact-us' ? "active" : ''} to="/contact-us" style={{textDecoration: 'none'}}>Contact Us</Link></Typography>
                <Typography style={{padding: '6px'}}><Link to="/download" style={{textDecoration: 'none'}} className="btn" activeClassName='btn-active'>Download</Link></Typography>
            </Toolbar>
        </Container>
      </AppBar>
    )
  }

  render(){
    return(
      <div>
        {this.destroyDrawer()}
        {this.createDrawer()}
      </div>
    );
  }
}

export default ResAppBar;